import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Logo from '../elements/logo';
import {footerMenu} from '../../utils/menu.type';

interface IconItem {
  icon: string;
  name: string;
  link: string;
}

const iconItems: IconItem[] = [
  {
    icon: 'telegram.svg',
    name: 'Telegram',
    link: 'https://t.me/amber_decision'
  },
  {
    icon: 'discord.svg',
    name: 'Discord',
    link: 'https://discord.gg/pcTEApgfCS'
  },
  {
    icon: 'twitter.svg',
    name: 'Twitter',
    link: 'https://twitter.com/DECISIONbet'
  },
  {
    icon: 'email.svg',
    name: 'Email',
    link: 'mailto:team@decision.bet'
  }
];

export default function Footer() {
  const year = new Date().getFullYear()
  return (
    <section className='relative flex flex-col items-center justify-center text-white bg-gradient-to-b from-black to-blue-950'>
      <div className='relative w-full px-4 lg:px-8 mt-4 lg:mt-8'>
        <div className='flex flex-wrap gap-8 lg:flex-row lg:gap-4 text-center items-center justify-between'>
          {footerMenu.map((item, index) => (
            <Link
              href={item.href}
              key={index}
              className={'text-white cursor-pointer text-[10px] lg:text-[20px] font-ubuntu font-[700] uppercase'}
            >
              <div>{item.name}</div>
            </Link>
          ))}
        </div>

        <div className='mt-10 lg:mt-16'>
          <div className='flex flex-col lg:flex-row justify-between items-center gap-8 '>
            <div className='flex items-center justify-center'>
              <Logo />
            </div>
            <div className='relative flex justify-center items-center w-[350px] h-[90px] lg:w-[590px] lg:h-[120px] md:w-[450px] md:h-[110px] group transition-transform transform hover:scale-105'>
              <Link
                  href='https://polygonscan.com/token/0x8865bc57c58be23137ace9ed1ae1a05fe5c8b209'
                  target='_blank'
              >
                <Image
                className='drop-shadow-[0px_0px_5px_black] '
                src='/elements/polygonChain.webp'
                alt='background image'
                fill
                loading='lazy'
              />

                <div className='relative text-center cursor-pointer'>
                  <p className='text-[12px] md:text-[14px] lg:text-[18px] text-gray-300 group-hover:text-white text-center font-georgia font-[400]'>
                    Smart Contract Address, Polygon Mainnet POS
                  </p>
                  <p className='text-[12px] md:text-[14px] lg:text-[18px] text-gray-300 group-hover:text-white text-center font-ubuntu font-[700]'>
                    0x8865bc57c58be23137ace9ed1ae1a05fe5c8b209
                  </p>
                </div>
              </Link>
            </div>

            <div className='cursor-pointer flex gap-8'>
              {iconItems.map((item) => (
                <Link key={item.name} href={item.link} target='_blank'>
                  <Image
                    src={`/icons/${item.icon}`}
                    className='aspect-[1/1] transition-transform transform hover:scale-110'
                    alt='background image'
                    width={34}
                    height={34}
                    loading='lazy'
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='relative p-4 lg:p-16'>
        <p className='text-gray-400 text-center text-[14px] font-ubuntu font-[500]'>
          © Decision.bet, Inc. {year} All those mentioned are the property of
          their respective owners.
        </p>
      </div>
    </section>
  );
}
